.radio-buttons-list{
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    font-weight: 700;
    color: #616161;
    align-self: flex-start;
    font-size: 1rem;
    line-height: 1.188rem;
    align-items: center;
    margin-bottom: 0.375rem;
  }


  li{
    list-style-type: none;
    margin-bottom: 0.5rem;


    .radio-button{
      min-height: unset;
      flex-direction: row-reverse;
      justify-content: flex-end;

      input{
        height: 1.25rem;
        width: 1.25rem;
        margin-right: 1rem;
      }
    }
  }
}