@import '../../styling/constants';
.cases {
  &__wrapper{
    &__title{
      h1{
        text-align: center;
      }
  }
  }

  &__content {
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__add{
      margin-bottom: 1rem;
    }

    &__list{
      width: 100%;

      &__item {
        display: flex;
        align-items: center;
        margin: 1rem 0;
        padding: 1rem;
        cursor: pointer;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &__title{
          margin-right: 0.5rem;
        }

        &:hover{
          background-color: $secondary-hover-background;
        }
      }
    }


  }
}