.radio-button__wrapper{
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .input-wrapper{
    display: flex;
    align-items: center;
    //height: 2rem;
    min-height: 2rem;
    margin-bottom: 0;

    label{
      margin-bottom: 0;
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
}