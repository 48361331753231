.input-wrapper {
  &__file {

    input[type="file"] {
      overflow: hidden;
      width: 5.938rem;
    }

    img{
      height: 1rem;
    }
  }
}