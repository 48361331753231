@import '../../styling/constants';

.add-case {

  &__form{
    margin-top: 3rem;

    & > div {
      margin: 1rem 0;
      &:first-child{

      }
    }

    .input-wrapper{
      width: unset;
    }
  }
  &__patient-relationship__section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      font-weight: 700;
      color: $primary-gray;
      line-height: 1.188rem;
      margin-bottom: 0.375rem;
    }

    li {
      list-style-type: none;
      margin-bottom: 0.5rem;

      .radio-button {
        min-height: unset;
        flex-direction: row-reverse;
        justify-content: flex-end;

        label {
          margin-bottom: 0;
        }

        .input {
          height: 1.25rem;
          width: 1.25rem;
          margin-right: 1rem;

        }
      }
    }
  }

  &__footer{
    display: flex;
    justify-content: center;
  }
}
