@import '../../styling/constants';

.case {
  &__wrapper {
  }

  &__header {
    h1 {
      text-align: center;
    }
  }

  &__body {
    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 2rem 0;

      p {
        word-break: break-all;
      }
    }

    &__footer {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .btn {
        margin: 0;

        &:first-child {
          margin-right: 1rem;
        }
      }

      &__item {
        display: flex;
        justify-content: center;
        margin: 0 1rem;
      }
    }
  }
}

.btn {
  a {
    color: $white;
  }
}
