$primary-color: #f7069554;
$secondary-color: #b24185;
$secondary-pink: #752c58;
$white: #fff;
$primary-gray: #727272;
$secondary-gray: #b7b7b7;
$gray-1: #444;
$page-background-color: #b6b6b6;
$red: #c70303;
$secondary-hover-background: #e2c9d8;
$dark-text-color: #252525;
$primary-color-blue: #0d4183;
$primary-color-blue-accent: #215495;
$primary-color-blue-dark-accent: #092f5f;
$nav-menu-accent: #487cba;
$primary-green: #cef4e2;
$secondary-green: #097057;
$nav-menu-accent: #487cba;
$blue: #1d86f0;
$light-blue: #e6effd;
$secondary-color: #b24185;
$secondary-pink: #752c58;
$primary-gray: #616161;
$secondary-gray: #bbbbbb;
$tertiary_gray: #848484;
$scrollbar-container-gray: #e2e2e2;
$calendar-gray: #ddd;
$gray-1: #444444;
$black: #000;
$white: #fff;
$dark-text-color: #252525;
$page-background-color: #f2f2f2;
$light-blue: #e6effd;
$red: #e02a4b;
$text-highlight: #ffe587;
$success-green: #118915;
$failure-red: #ff0000;
$secondary-hover-background: #e2c9d8;
$approved: #118915;
$require-changes: #e02a4b;
$in-review: #b65b07;
$pending: #0c75df;
$draft: #616161;
$approve-btn: #54ab57;
$require-changes-btn: #cb4059;
$google-blue: #4285f4;

$spinner-z-index: 10;
$navbar-z-index: 11;

@-webkit-keyframes rotateit {
  from {
    -webkit-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
  }
}
@-moz-keyframes rotateit {
  from {
    -moz-transform: rotate(360deg);
  }
  to {
    -moz-transform: rotate(0deg);
  }
}
@keyframes rotateit {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
