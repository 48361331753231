@import '../../styling/constants';

.input-wrapper {
  position: relative;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  min-height: 5.75rem;
  height: auto;

  &.radio-btn {
    flex-direction: row-reverse;
    height: unset;
    font-weight: 400;
    font-size: 0.875rem;

    input {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }

    label {
      margin-bottom: unset;
      font-size: 0.875rem;
      color: unset;

      span {
        font-weight: 400;
      }
    }
  }

  &--error {
    label {
      color: $red;
    }
    span {
      color: $red;
    }
    input {
      border: 1px solid $red;
    }
  }
  .subtext {
    font-size: 0.75rem;
  }
}
.input-label {
  font-weight: 400;
  color: $primary-gray;
  align-self: flex-start;
  font-size: 1rem;
  line-height: 1.188rem;
  align-items: center;
  margin-bottom: 0.375rem;

  span {
    font-weight: 700;
  }
}

.input-validation {
  display: flex;
  color: $red;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.063rem;
  position: relative;
  bottom: -0.1875rem;

  &__radio {
    left: 2.8rem;
    font-weight: 400;
    line-height: 1.25rem;
    font-size: 0.875rem;
    bottom: 0rem;
    top: 0.5rem;
  }
}

.input {
  font-family: inherit;
  font-size: 0.875rem;
  border: 1px solid $secondary-gray;
  border-radius: 8px;
  background-color: $white;
  padding: 0.625rem;
  width: 100%;
  height: 2.5rem;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  &:disabled {
    color: $primary-gray;
    background-color: $page-background-color;
  }
}

//@media only screen and ($tablet-width-medium) {
//  .input-wrapper {
//    height: 7rem;
//  }
//}
