@import '../../styling/constants';

.btn {
  border: none;
  display: flex;
  border-radius: 8px;
  width: 100%;
  padding: 0.625rem;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  color: $secondary-color;
  cursor: pointer;
  width: 15rem;
  height: 3rem;

  a, a:hover{
    color: $white;
  }

  &__text {
    padding: 0.75rem 1.25rem;
    font-weight: 700;
  }

  &--pink {
    background: $secondary-color;
    border: 1px solid $secondary-color;
    color: $white;

    &:hover {
      background: $secondary-pink;
      border: 0;
    }

    &:focus {
      border: 1px solid $gray-1;
    }

    &:disabled {
      background: $secondary-gray;
      border: none;
      cursor: not-allowed;
    }
  }

  &--transparent {
    background-color: transparent;
    border: 1px solid $secondary-color;
    color: $secondary-color;

    &:hover {
      background-color: $secondary-hover-background;
    }
  }


  //&--red {
  //  background-color: $require-changes-btn;
  //  border: 1px solid $require-changes-btn;
  //  color: $white;
  //
  //  &:hover {
  //    background-color: $secondary-hover-background;
  //  }
  //}

  &--blue {
    background-color: $primary-color;
    color: $white;
  }

  &--white {
    justify-content: left;
    background-color: $white;
    color: $dark-text-color;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
  }

  &--disabled {
    background-color: $secondary-gray;
    color: $white;
    pointer-events: none;
  }

  &--inactive {
    background-color: $secondary-gray;
    color: $white;
  }

  &__icon {
    height: 1.875rem;
    width: 1.875rem;
    margin: 0 0.125rem;
    cursor: pointer;
    border: none;

    &--edit {
    }

    &--delete {
    }

    &--add {
      justify-content: flex-start;
      text-align: flex-start;
      margin-right: auto;

      img {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }
  }

  //Button Size

  &--x-small {
    font-size: 0.875rem;
  }

  &--small {
    font-size: 1rem;
  }

  &--medium {
    font-size: 1.125rem;
  }

  &--large {
    font-size: 1.25rem;
  }

  &--width-small {
    width: min-content;
    padding: 1rem 1.25rem;
  }

  &--borderless {
    background-color: $white;
    color: $gray-1;
    border: none;
  }
  //&--icon {
  //  width: fit-content;
  //  display: flex;
  //  align-items: center;
  //  margin-right: 1.125rem;
  //  cursor: pointer;
  //  color: $pending;
  //  font-weight: 700;
  //  font-size: 0.875rem;
  //  line-height: 1.25rem;
  //  white-space: nowrap;
  //  border: none;
  //  padding: 0;
  //  &:hover {
  //    background-color: unset;
  //  }
  //
  //  div {
  //    display: flex;
  //    align-items: center;
  //    justify-content: center;
  //    background-color: $pending;
  //    border-radius: 8px;
  //    width: 1.875rem;
  //    height: 1.875rem;
  //    margin-right: 0.5rem;
  //    align-items: center;
  //  }
  //}
}
