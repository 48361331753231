@import '../../styling/constants.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: $spinner-z-index;

  &__wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__spinner {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-animation: rotateit 1.3s linear infinite;
    -moz-animation: rotateit 1.3s linear infinite;
    animation: rotateit 1.3s linear infinite;

    &__text{
      color: $white;
    }
  }
}

.spinner-text{
  .overlay__wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .overlay__spinner{
    position: relative;
    margin: 0;
  }
}

.simple-spinner {
  display: flex;
  img {
    margin: auto;
    -webkit-animation: rotateit 1.3s linear infinite;
    -moz-animation: rotateit 1.3s linear infinite;
    animation: rotateit 1.3s linear infinite;
  }
}