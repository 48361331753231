@import '../../styling/constants';
@import '../../styling/breakpoints';

.login {
  background-color: $primary-color;
  border-radius: 10px;
  padding: 3rem 5rem;

  .btn {
    width: 50%;
  }

  @include for-tablet-portrait-up {
    margin: 0 25%;
  }
}
