@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content
  }
}


@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content
  }
}
